
import auth from './auth';
import users from './users';
import groups from './groups';
import badges from './badges';
import responses from './responses';
import chat from './chat';
import surveys from './surveys';

const run = () => {
    // auth.run();
    users.run();
    // groups.run();
    badges.run();
    // responses.run();
    // chat.run();
    // surveys.run();
};

export default run;
